/*! original idea from: https://jsfiddle.net/kthornbloom/zJ6kp/ */

.timeline .fc-view {
  $entry-width: 200px;
  $entry-height: 110px;
  $entry-padding: 10px;
  $entry-padding-bar: 20px;
  $entry-margin-right: 20px;

  .bar {
    height: 5px;
    background: #eee;
    width: 100%;
    position: relative;
    left: 0;
  }

  .entries {
    white-space: nowrap;
    overflow-x: scroll;
    position: relative;
  }

  @mixin entries-rail {
    height: $entry-height + $entry-padding-bar + 5px;
  }

  .entries-upper {
    @include entries-rail;
    padding-bottom: $entry-padding-bar;
    padding-left: $entry-width / 2 + $entry-margin-right / 2;
  }

  .entries-lower {
    @include entries-rail;
    padding-top: $entry-padding-bar;
  }

  @mixin entry-base {
    text-decoration: none;
    display: inline-block;
    vertical-align: top;
    padding: $entry-padding;
    color: #050137;
    background: #fff;
    text-align: center;
    position: relative;
    border-radius: 3px;
    width: $entry-width;
    height: $entry-height;
    margin-right: $entry-margin-right;

    &:after {
      content: '';
      display: block;
      background: #eee;
      width: 7px;
      height: 7px;
      border-radius: 6px;
      border: 3px solid #06182E;
      position: absolute;
      left: 10%;
      margin-left: -6px;
      z-index: 1;
    }

    &:before {
      content: '';
      display: block;
      background: #0de9d2;
      width: 5px;
      height: 20px;
      position: absolute;
      left: 10%;
      margin-left: -2px;
    }

    hr {
      margin: 3px 0;
    }

    .title {
      text-align: left;
      font-weight: bold;
      font-size: 14px;
    }

    .name {
      text-align: left;
      font-size: 14px;
    }

    .time {
      text-align: left;
      font-weight: bold;
      font-size: 14px;
    }

    .date {
      text-align: left;
      font-size: 14px;
    }
  }

  .entry-top {
    @include entry-base;
    border: 1px solid #666;
    border-bottom: 4px solid #0de9d2;

    &:after {
      top: $entry-height + $entry-padding-bar + 20px;
    }

    &:before {
      top: $entry-height + $entry-padding-bar;
    }
  }

  .entry-bottom {
    @include entry-base;
    border: 1px solid #666;
    border-top: 4px solid #0de9d2;

    &:after {
      top: -30px;
    }

    &:before {
      top: -20px;
    }
  }
}
