body {
  background-color: #f6f8fa;
}

:focus {
  outline: -webkit-focus-ring-color auto 0px;
  outline-color: -webkit-focus-ring-color;
  outline-style: auto;
  outline-width: 0px;
}

.navbar-default {
  background-color: #050137;
  border: none;
}

.navbar-default .navbar-nav > li > a {
  color: #FFF;
}

.fc-state-default.fc-corner-right {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.fc-state-default.fc-corner-left {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.fc-left .fc-state-default {
  background-image: none;
  background-color: #f6f8fa;
  border: 1px solid #050137;
  color: #050137;
  box-shadow: none;
  text-shadow: none;
  border-color: #050137;
}

#sites > h3, #sites > label{
  color: #050137;
}

.fc-body {
  background-color: #FFF;
}

#calendar, #timeline {
  margin-left: 15px;
  margin-right: 15px;
}

#wrapper {
  background-color: #f6f8fa;
  margin-top: 25px;
}

#sidebar {
  margin-top: 25px;
}

#sidebar label {
  color: #050137;
}

#sidebar {
  background-color: #FFF;
  box-shadow: 2px 2px 2px 2px #000;
}

.fc-button-group .fc-state-default {
  background-image: none;
  background-color: inherit;
  border: 0px solid #0de9d2;
  border-bottom: 1px solid #0de9d2;
  border-top: 1px solid #0de9d2;
  border-radius: 0px;
  color: #FFF;
}

.fc-button-group .fc-corner-left {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
  border-left: 1px solid #0de9d2 !important;
}

.fc-button-group .fc-corner-right {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  border-right: 1px solid #0de9d2 !important;
}

.fc-button-group .fc-state-active {
  color: #050137 !important;
  background-color: #0de9d2 !important;
}

.fc-event {
  border-radius: 8px;
  padding-left: 10px;
  overflow: hidden;
}

.fc th {
  text-align: left;
  border-top: 0px solid #f6f8fa;
  border-left: 0px solid #f6f8fa;
  border-right: 0px solid #f6f8fa;
  color: #a8a8a8;
}

.fc-ltr .fc-basic-view .fc-day-number {
  text-align: left;
  color: #656363;
}

.fc-unthemed th, .fc-unthemed td, .fc-unthemed thead, .fc-unthemed tbody, .fc-unthemed .fc-divider, .fc-unthemed .fc-row, .fc-unthemed .fc-content, .fc-unthemed .fc-popover {
  border-color: #d8dce5;
}

.fc-head-container {
  border-top: 0px !important;
  border-left: 0px !important;
  border-right: 0px !important;
}

.slidebar-nav {
  padding-left: 25px;
}

.checkbox {
  margin-bottom: 0px;
  height: 44px;
}

#sidebar .radio input[type="radio"], #sidebar .radio-inline input[type="radio"], #sidebar .checkbox input[type="checkbox"], #sidebar .checkbox-inline input[type="checkbox"] {
  position: absolute;
  margin-left: -35px;
}

.checkbox-selector {
  border-bottom: 1px solid #e6e5f2;
  height: 40px;
  position: relative;
  left: -25px;
  width: 100%;
  transition: 1s ease border-left;
  border-left: 5px solid #000;
}

.checkbox-selector-active {
  border-left: 35px solid #000;
}

.checkbox-icon {
  transition: 1s ease background;
  height: 40px;
  top: -30px;
  position: relative;
  left: -20px;
  background:url('../img/eye.png') no-repeat;
}

#wrapper.toggled {
  .checkbox-selector {
    left: 0;
  }
  .checkbox-icon {
    left: 0;
  }
}

.checkbox-icon-active {
  background:url('../img/eye2.png') no-repeat;
}

#sidebar label {
  position: relative;
  top: -20px;
}

#sidebar input {
  display: none;
}

.checkbox-container {
  height: 40px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:hover, .navbar-default .navbar-nav > .open > a:focus {
  background-color: transparent;
  color: #0de9d2;
}

.navbar-default .navbar-nav > li > a:hover, .navbar-default .navbar-nav > li > a:focus {
  color: #0de9d2;
  background-color: transparent;
}

@media (min-width: 768px) {
  .container > .navbar-header, .container > .navbar-collapse, .container-fluid > .navbar-header, .container-fluid > .navbar-collapse {
    padding-top: 14px;
    padding-bottom:7px;
  }
  .navbar > .container .navbar-brand, .navbar > .container-fluid .navbar-brand {
    margin-left: 0px;
  }
}
