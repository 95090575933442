$sidebar-width: 350px;

body {
  overflow-x: hidden;
}

#sidebar {
  max-width: 90%;
}

#wrapper {
  padding-left: 0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;

}

#wrapper.toggled {
  padding-right: $sidebar-width;

  .slidebar-nav {
    width: $sidebar-width;
  }
}

.slidebar-form {
  padding-top: 7px;
  padding-right: 15px;
  padding-bottom: 13px;
  padding-left: 15px;
}

.slidebar-nav {
  right: $sidebar-width;
  border-left: 1px solid grey;
  list-style: none;
  height: 100%;
  margin: 0;
  margin-right: -$sidebar-width;
//overflow-y: scroll;
  padding: 0 10px;
  position: fixed;
  top: 50px;
  width: 0;
  z-index: 1000;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;

  .navbar-collapse {
    padding: 0;
    max-height: none;
  }

  ul {
    float: none;
    width: 100%;
  }

  ul:not {
    display: block;
  }

  li {
    float: none;
    display: block;
  }

  li a {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .open .dropdown-menu {
    position: static;
    float: none;
    width: auto;
    margin: 0;
    padding: 5px 0;
    background-color: transparent;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;

    > a > b.caret {
      border-top: none;
      border-bottom: 4px solid;
    }

    > li > a {
      padding: 5px 15px 5px 25px;
    }
  }

  .navbar-nav {
    margin: 0;
  }

  .navbar-brand {
    width: 100%;
  }
}

.slidebar-toggle {
  overflow-x: show;
  position: absolute;
  top: 20px;
  left: -31px;

  .btn {
    width: 30px;
    height: 2.1em;
  }
}

.fc-toolbar {
  padding-right: 35px;
}

#wrapper .slidebar-toggle .btn span::before {
  content: '>';
}

#wrapper.toggled .slidebar-toggle .btn span::before {
  content: '<';
}

@media(min-width:768px) {
  #wrapper {
    padding-right: $sidebar-width;
  }

  #wrapper.toggled {
    padding-right: 0;

    .slidebar-nav {
      width: 0;
      padding: 0;
    }
  }

  .slidebar-nav {
    width: $sidebar-width;
  }
}

@media(max-width:767px) {
  #wrapper {
    padding-right: 0;
  }

  #wrapper.toggled {
    padding-right: 0;

    .slidebar-nav {
      width: 0;
      padding: 0;
    }
  }

  .slidebar-nav {
    width: $sidebar-width;
  }

  .fc-toolbar h2 {
    font-size: 1em;
  }

  .sidebar-sites {
    overflow-y: scroll !important;
  }

  .checkbox-selector {
    left: 0 !important;
  }

  .checkbox-icon {
    left: 0 !important;
    margin-left: 5px;
  }

  .checkbox label {
    left: 20px !important;
  }
}

.event-log {
  margin-top: 20px;
}
