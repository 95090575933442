.navbar-brand {
  padding: 0px;

  img {
    height: 100%;
    padding: 5px;
    width: auto;
  }
}

button.navbar-right {
  margin-right: 5px;
}

.navbar-user {
  padding: 0 !important;
  vertical-align: middle;

  .navbar-user-logo {
    display: inline-block;
    vertical-align: middle;
    width: 50px;
    height: 50px;
    position: relative;
    border-radius: 100%;
    overflow: hidden;
    margin-left: 5px;
    margin-right: 5px;

    img {
      position:absolute;
      left:-50%;
      right:-50%;
      top:0;
      margin:auto;
      height:100%;
      width:auto;
    }
  }
}

#navbar ul.view-switch-large {
  position: relative;
  top: 10px;
  left: 0;
  text-align: middle;
  width: 50%;

  li {
    text-align: right;

    a {
      padding: 5px;
    }
  }

  .fc-sep {
    width: 10px;
  }
}

#navbar ul.view-switch-small {
  .fc-sep {
    height: 10px;
  }
}

.navbar {
  button.navbar-toggle {
    border-color: #0de9d2 !important;

    &:focus {
      background-color: inherit;
    }
  }

  span.icon-bar {
    background-color: #0de9d2 !important;
  }
}
