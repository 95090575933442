.new-event-modal {
  .event-time-input {
    display: inline-block;
    width: 22%;
  }

  .time-sep {
    padding-left: 3px;
    padding-right: 3px;
  }

  .event-step {
    display: inline-block;
    border: 1px solid black;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    text-align: center;
    padding-top: 5px;
    margin-right: 5px;
  }

  .event-step.active {
    background-color: lightgrey;
  }

  textarea {
    max-width: 100%;
    min-height: 150px;
  }

  .new-client {
    height: 33px;
    padding-top: 6px;
  }
}
